import React, { useEffect, useState } from "react";
import { TableComp } from "../../Common/TableComp";
import { FieldRepColumns } from "./Data";
import { useFieldRepsQuery } from "../../../store/DashBoard/DashBoardApi";
import OffCanvasComp from "../../../Components/Ui/OffCanvas.jsx";
import AddFieldRep from "./modules/addFieldRep";
import { useNavigate } from "react-router-dom";
import ButtonComp from "../../Ui/Button";
const FieldRep = () => {
  const [showCavans, setShowCavans] = useState(false);
  const [getFieldRep, setFieldRep] = useState([]);
  const [edit, setEdit] = useState(true);
  const { data, isLoading, isSuccess, refetch } = useFieldRepsQuery();

  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      setFieldRep(data?.data);
    }
  }, [data?.data, isSuccess]);

  const tableDropDown = [
    {
      name: "Create Field Rep",
      action: (row) => {
        // console.log(row,'row')
        // navigate(`/product/${row?.id}`);
        setShowCavans(true);
      },
    },
  ];

  // console.log(getFieldRep,'getFieldRep')
  return (
    <div className="pt-[10px]">
      <div className="flex justify-between items-center mb-5">
        <div>
          <div className="text-[20px] text-[#333333] font-semibold">
            Field Representatives
          </div>
          <p className="text-[#555555] font-medium">
            Add new field representative and update them
          </p>
        </div>
        <div className="flex items-center gap-3">
          <ButtonComp
            text={"Discard"}
            btnClassName={
              "border-[#D63434] border-[1px] py-[8px] px-[30px] rounded-full"
            }
          />
          <ButtonComp
            onClick={() => setShowCavans(true)}
            btnClassName={
              "border-[#3DA84A] bg-[#3DA84A] text-white border-[1px] py-[8px] px-[20px] rounded-full"
            }
            text={"Add New Rep"}
          />
        </div>
      </div>
      {/*  */}
      <TableComp
        Data={[...getFieldRep]?.reverse()}
        columnsComp={FieldRepColumns(
          tableDropDown,
          data?.data?.length > 0 && [...data?.data]?.reverse()
        )}
        pagination
      />
      <OffCanvasComp
        title={"Field Reps"}
        show={showCavans}
        bodyComponent={
          <AddFieldRep
            edit={edit}
            refetch={refetch}
            setShowCavans={setShowCavans}
            // onNext={()=>{
            //   refetch();
            //   setShowCavans(false)
            // }}
          />
        }
        handleClose={() => {
          setShowCavans(false);
          // navigate(`/order_management`);
        }}
      />
    </div>
  );
};

export default FieldRep;
