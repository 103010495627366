export const formatMoney = (amount, koboRes = true,currencySymbol ='₦') => {
    if (!amount) return null;
    //ensure all commas are striped from the amount
    amount = String(amount).replace(/,/g, "");
    //split the amount into naira and kobo
    //format the naira and add the kobo at the end of the process
    amount = String(amount).split(".");
    let naira = amount[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    let kobo = String(amount[1]);
    if (!kobo || kobo === "undefined") kobo = "00";
    return koboRes ? `${currencySymbol} ${naira}.${kobo}` :`${currencySymbol}${naira}`;
};
