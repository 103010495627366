import React from "react";
import { Form } from "react-bootstrap";

export default function TextareaComp({
  containerClassName,
  label,
  labelClassName,
  wrapperClassName,
  placeholder,
  rows = 5,
  error = "",
  ...props
}) {
  return (
    <div className={wrapperClassName}>
      {label && (
        <div className={`mb-[10px] font-medium ${labelClassName}`}>{label}</div>
      )}

      <Form.Control
        as="textarea"
        rows={rows}
        className="placeholder:text-[#757575] "
        placeholder={placeholder ? placeholder : `Enter ${label || ""}`}
        {...props}
      />
      {error && (
        <div className="text-danger text-start ps-1">
          {label} {error}
        </div>
      )}
    </div>
  );
}
