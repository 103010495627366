import React from 'react'
import ListViewInCanvas from '../../../Common/ListViewInCanvas'
import { ImageComponent } from '../../../Common/FallbackImage'
import ButtonComp from '../../../Ui/Button'
import moment from 'moment'

export default function ViewProductDetails({data}) {
  return (
    <div>
        <div className='border-[rgba(79, 0, 138, 0.1)] border-[1px] py-[50px] pl-[20px] xl:pl-[30px] pr-[20px] xl:pr[0px] flex-1 mb-[30px]'>
       <div className='row gy-4'>
        <div className='col-lg-6 '>
            <ListViewInCanvas
            title={'Field Rep Name'}
            ItemName={`${data?.user?.firstName} ${data?.user?.lastName}`}
            />
        </div>
        <div className='col-lg-6 '>
            <ListViewInCanvas
            title={'Customer/Company'}
            ItemName={data?.customer?.businessName}
            />
        </div>
        <div className='col-lg-6 '>
            <ListViewInCanvas
            title={'Customer Business Name'}
            ItemName={data?.customer?.businessName}
            />
        </div>
        <div className='col-lg-6 '>
            <ListViewInCanvas
            title={'Customer Business PhoneNumber'}
            ItemName={data?.customer?.businessPhoneNumber}
            />
        </div>
       
        
        <div className='col-lg-6 '>
            <ListViewInCanvas
            title={'Location'}
            ItemName={data?.customer?.address}
            />
        </div>
        <div className='col-lg-6 '>
            <ListViewInCanvas
            title={'Schedule Date'}
            ItemName={moment(data?.scheduleDate).format("MMM Do YY")}
            />
        </div>
        <div className='col-lg-6 '>
            <ListViewInCanvas
            title={'Visitation Reason'}
            ItemName={data?.visitationReason}
            />
        </div>
        <div className='col-lg-6 '>
            <ListViewInCanvas
            title={'Status'}
            ItemName={data?.status}
            />
        </div>
       
       
        
       </div>
    </div>
    <div className='flex justify-center gap-[20px]'>
        <ButtonComp
        text={'Delete Product'}
        btnClassName={'border-[#D63434] border-[1px] text-[#282728] rounded-full px-[25px] py-2 text-[14px] font-medium'}
        />
        <ButtonComp
        text={'Edit Product'}
        btnClassName={'border-[#4F008A] bg-[#4F008A] border-[1px]  rounded-full px-[35px] text-white py-2 text-[14px] font-medium'}
        />
    </div>
    </div>
  )
}
