import React from 'react'
import { ImageComponent } from './FallbackImage'
import Skeleton from 'react-loading-skeleton';
import {RiMenu2Line} from 'react-icons/ri'
import { useTranslation } from "react-i18next";

export default function Header({data,setToogle}) {
  const {  i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  console.log(data,'userProfile')

  return (
    <div className='flex justify-between items-center'>
      <div className='cursor-pointer d-lg-none' onClick={()=>setToogle(true)}>
        <RiMenu2Line size={30}/>
      </div>

      <div className="py-3 d-flex gap-2 gap-lg-4 justify-content-end border-bottom w-full">
      {/* <button onClick={() => changeLanguage("fr")}>tr</button>
        <button onClick={() => changeLanguage("en")}>en</button> */}
        <div className="bg-gray rounded-circle p-3 d-flex justify-content-center align-items-center" style={{width:'60px',height:'60px'}}>
          
            <ImageComponent width={24} height={24} src='/icon/global.png'/>
          </div>
          <div className="bg-gray rounded-circle p-1 d-flex justify-content-center align-items-center" style={{width:'60px',height:'60px'}}>
            <ImageComponent width={20} height={20} src='/icon/notification-bing.png'/>
          </div>

          <div className="bg-gray d-flex ps-[30px] pe-[10px] rounded-pill py-1 align-items-center gap-2 ">
            <div>
              <div className="blackI font-medium text-[14px]">{data?.firstName} {data?.lastName}</div>
              <div className="blackIII text-[12px] capitalize">{data?.userRole?.split('_').join(' ')||<Skeleton baseColor='#4f008a4d' width={20}/>}</div>
            </div>
            <ImageComponent width={40} height={40} src='/icon/user.png'/>
          </div>
        </div>
    </div>
  )
}
