import React from "react";
import AdminLayout from "../../Layout/AdminLayout";
import AddProductComp from "./AddProduct";
import AddCompanyComp from "../../Components/modules/Product/addproduct";

export default function AddCompany() {
  return (
    <AdminLayout active={4}>
      <AddCompanyComp />
    </AdminLayout>
  );
}
