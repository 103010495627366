import React, { useEffect, useState } from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  
} from "react-google-maps";
import Autocomplete from "react-google-autocomplete";
import { useGetLocationsQuery } from "../../../store/Customer/CustomerApi";
import { useParams } from "react-router-dom";

const ShowMapComp = () => {
  const {customerId,id} =useParams();
  const google = window.google;

  const { data: DataMap, isLoading,isSuccess } = useGetLocationsQuery({id:id},{skip:!customerId, 
    refetchOnMount: true,
    refetchInterval: 3000, 
    // Refresh interval in milliseconds
  });

  const [mapState, setMapState] = useState({
    center: { lat: Number(DataMap?.data[0]?.latitude), lng: Number(DataMap?.data[0]?.longitude) },
    zoom: 13
  });

  useEffect(() => {
    setMapState({
      center: { lat: Number(DataMap?.data[0]?.latitude), lng: Number(DataMap?.data[0]?.longitude) },
      zoom: 13
    })
  }, [isSuccess])
  

  const onPlaceSelected = (place) => {
    setMapState({
      center: place.geometry.location,
      zoom: 18
    });
  };



  const Auto = (props) => (
    <Autocomplete
      style={{ width: "90%" }}
      onPlaceSelected={(place) => {
        console.log(place);
        onPlaceSelected(place);
      }}
      types={["(regions)"]}
    />
  );

  if (!isSuccess  ) return <div>Loading...</div>;


  const GoogleMapExample = withGoogleMap((props) => (
    <GoogleMap center={mapState.center} zoom={mapState.zoom}>
      {DataMap?.data?.map((item, i) => (
  <Marker 
  position={{ lat: Number(item?.latitude), lng: Number(item?.longitude) }}

    />

      ))}
    </GoogleMap>
  ));

  return (
    <div>
      <div style={{}}>
        {/* <h3>Choose another destination</h3> */}
        <Auto />
      </div>
      <GoogleMapExample
        containerElement={<div style={{ height: `90vh`, width: "100%" }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  );
};

export default ShowMapComp;