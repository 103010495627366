import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { MdClose } from "react-icons/md";
import ButtonComp from "../../../Ui/Button";
import { useAddProductCsvMutation } from "../../../../store/Product/ProductApi";
import { toast } from "react-toastify";
import { Typewriter } from 'react-simple-typewriter'
import { useNavigate } from "react-router-dom";

export default function AddProductCsv({setProductCsv}) {
    const [csvFile,setCsvFile] =useState([{name:''}]);
    const navigate =useNavigate()
    console.log(csvFile)
    const [addProductCsv,{isLoading,isError,isSuccess,data}] =useAddProductCsvMutation();

    useEffect(() => {
      if(isSuccess){
        toast.success('Products Update Successful...')
        setProductCsv(false)
      }
    }, [isSuccess])
    

    const handleChange = async(file) => {
        setCsvFile(file)
    };

    // alert(csvFile?.name)

    const handleSubmit =async () =>{
      const fileUploaded = csvFile;
      const Form = new FormData();
        Form.append("productCSV", fileUploaded);
        
        const response =await addProductCsv(Form);

        console.log(response,'response')
    }

    function downloadFile(url) {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', true);
  link.click();
}

function handleDownload() {
  const fileUrl = '/Document/priceList.xlsx';
  downloadFile(fileUrl);
  
  // Navigate to a different route (optional)
  // history.push('/another-route');
}
  return (
    <div className="px-4">
      <div className="text-[21px]  font-semibold">Upload CSV</div>
      <div className="text-[16px] flex text-[#757575] mb-3  hover:cursor-pointer " onClick={handleDownload}>
        CSV file upload guidelines? {" "} <div className="text-[#4F008A]">
        <Typewriter
            words={['Click', 'Download', 'Template']}
            loop={5}
            cursor
            ca
            cursorStyle='_'
            typeSpeed={70}
            deleteSpeed={50}
            delaySpeed={1000}
            // onLoopDone={handleDone}
            // onType={handleType}
          />
        </div>
      </div>

      <div className="border-dashed border-2 border-[#4F008A] py-5 mb-3">
        <Dropzone accept={{ "text/csv": [".csv"] }} onDrop={(acceptedFiles) => handleChange(acceptedFiles[0])}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="text-center flex justify-center mb-2">
                  <img src="/icon/draganddrop.svg" />
                </div>
                <p className="text-center text-[#4F008A] font-semibold mb-[4px]">
                  Select a CSV file to upload
                </p>
                <p className="text-center text-[#4F008A]">
                  or drag and Drop it here
                </p>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
      <div className="text-[#4F008A] text-[18px] font-semibold mb-2">
        Or upload from URL
      </div>
      <div className="bg-[#F9F7FB] px-3 py-3 border[2px] border-[#FDFCFD] mb-5">
        <span className="bg-[#4F008A] p-1 text-white rounded-sm">beta</span>
        <div className="flex justify-between ">
          <div>https://myaccount.google.com/?hl=en_GB&utm_source=OGB&</div>
          <div>Upload</div>
        </div>
      </div>
      {/*  */}
      <div className="bg-[#F9F7FB] px-3 py-3 border[2px] border-[#B7B7B766] mb-5">
        <div className="flex justify-between mb-3">
          <div className="flex justify-between">
            <div className="flex gap-3 items-center">
              <div>
                <img src="/icon/documentcsv.svg" />
              </div>
              <div>
                <div className="text-[#282728] font-medium">
                  {csvFile?.name||'No CSV Added...'}
                </div>
                <div className="text-[#A1A1A1] font-medium">{csvFile?.size}</div>
              </div>
            </div>
          </div>
          <div>
            <MdClose size={20} color="#4F008A" />
          </div>
        </div>
        <div className="bg-[#B7B7B7] rounded-sm">
          <div className={`bg-[#4F008A] h-[5px] ${csvFile?.name && 'w-[100%]'}`}></div>
        </div>
      </div>

      <div>
        <div className="flex justify-center  mb-[64px] gap-[15px]">
          <ButtonComp
            //   onClick={() => navigate("/")}
            text={"Cancel"}
            btnClassName={
              "text-[14px] lg:text-[16px]  py-[5px] lg:py-[13px]  px-[30px] lg:px-[64px] border-[0.64px] rounded-full border-[#D63434] text-[#282728]"
            }
          />
          <ButtonComp
          loader={isLoading}
             onClick={handleSubmit}
            btnClassName={
              "text-[14px] lg:text-[16px] py-[5px] lg:py-[13px] px-[25px] lg:px-[57px] border-[0.64px] rounded-full border-[#3DA84A] text-[#fff] bg-[#4F008A]"
            }
            text={"Upload File"}
          />
        </div>
      </div>
    </div>
  );
}
