import React from 'react'
import AdminLayout from '../../Layout/AdminLayout'
import VisitTargetComp from '../../Components/modules/VisitTarget'

export default function VisitTarget() {
  return (
    <AdminLayout active={5}>
      <VisitTargetComp/>
    </AdminLayout>
  )
}
