import React, { useState, useEffect } from "react";
import ListViewInCanvas from "../../../Common/ListViewInCanvas";
import SelectComp from "../../../Ui/SelectComp";
import {
  useAddFieldRepMutation,
  useGetCompanyQuery,
} from "../../../../store/Customer/CustomerApi";
import NaijaStates from "naija-state-local-government";
import { useSelector } from "react-redux";
import { selectCurrentUserData } from "../../../../store/auth";
import useFormValidator from "use-form-input-validator";
import ButtonComp from "../../../Ui/Button";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Country from "../../../../country.json";

export default function ViewCustomerDetailsComp({
  data = [],
  edit,
  onNext,
  setShowCavans,
  refetch,
}) {
  const [userData, setUserData] = useState(data);
  const [state, setState] = useState("");
  const [lgas, setLgas] = useState([]);
  const companyId = useSelector(selectCurrentUserData);

  const statesList = NaijaStates.all();
  const navigate = useNavigate();
  const { data: CompanyData, isLoading: LoadingCompany } = useGetCompanyQuery();
  const [
    addFieldRep,
    { isLoading, isSuccess, data: FieldData, isError, error },
  ] = useAddFieldRepMutation();

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
    handleSubmit && handleSubmit(userData);
  };

  const { values, errors, updateField, isAllFieldsValid } = useFormValidator({
    email: { checks: "required|email", value: "" },
    firstName: { checks: "required", value: "" },
    lastName: { checks: "required", value: "" },
    country: { checks: "required", value: "" },
    state: { checks: "required", value: "" },
    lga: { checks: "required", value: "" },
  });

  useEffect(() => {
    if (isSuccess) {
      toast.success("User Created Successfully...");
      refetch();
      setShowCavans(false);
    }
  }, [error, isError, isSuccess, onNext]);

  useEffect(() => {
    if (values.state) {
      setLgas(NaijaStates.lgas(values.state)?.lgas || []);
    }
  }, [values.state]);

  const handleSubmit = async () => {
    const value = {
      email: values?.email,
      firstName: values?.firstName,
      lastName: values?.lastName,
      userType: "supplier",
      userRole: "sales_rep",
      companyId: companyId?.companyId,
    };
    if (isAllFieldsValid()) {
      const response = await addFieldRep(value);
      if (response?.error) {
        toast.error(response?.error?.data?.message);
      }
    }
  };

  return (
    <>
      <div>
        <div className="border-[rgba(79, 0, 138, 0.1)] border-[1px] py-[50px] pl-[20px] xl:pl-[30px] pr-[20px] xl:pr[0px] flex-1 mb-[30px]">
          <div className="row gy-4">
            <div className="col-lg-6">
              <ListViewInCanvas
                title={"First Name"}
                ItemName={userData?.firstName}
                edit={edit}
                name="firstName"
                error={errors.firstName}
                onChange={(e) => {
                  updateField(e);
                  handleOnChange(e);
                }}
              />
            </div>
            <div className="col-lg-6">
              <ListViewInCanvas
                title={"Last Name"}
                ItemName={userData?.lastName}
                edit={edit}
                wrapperClassName="mb-3"
                labelClassName={"primaryI"}
                defaultValue={values?.lastName}
                name="lastName"
                onChange={updateField}
                error={errors.lastName}
              />
            </div>
            <div className="col-lg-12">
              <ListViewInCanvas
                title={"Country"}
                customItem={
                  <div className={statusStyleII(data?.status)}>
                    {userData?.country}
                  </div>
                }
                edit={edit}
                customItemI={
                  <SelectComp
                    value={userData?.country}
                    inputName={"name"}
                    labelClassName={"primaryI"}
                    wrapperClassName="mb-3"
                    name="country"
                    defaultValue={values.country}
                    options={Country}
                    error={errors.country}
                    onChange={updateField}
                  />
                }
              />
            </div>
            <div className="col-lg-6">
              <ListViewInCanvas
                title={"State"}
                customItem={
                  <div className={statusStyleII(data?.status)}>
                    {userData?.state}
                  </div>
                }
                edit={edit}
                customItemI={
                  <SelectComp
                    value={userData?.state}
                    inputName={"name"}
                    labelClassName={"primaryI"}
                    wrapperClassName="mb-3"
                    name="state"
                    defaultValue={values.state}
                    options={
                      values?.country
                        ? Country?.find(
                            (item) => item?.name === values?.country
                          )?.state
                        : []
                    }
                    error={errors.state}
                    onChange={updateField}
                  />
                }
              />
            </div>
            <div className="col-lg-6">
              <ListViewInCanvas
                title={"Local Government Area"}
                customItem={
                  <div className={statusStyleII(data?.status)}>
                    {userData?.lga}
                  </div>
                }
                edit={edit}
                customItemI={
                  <SelectComp
                    value={userData?.lga}
                    options={lgas.map((lga) => ({ name: lga, value: lga }))}
                    name="lga"
                    onChange={updateField}
                    inputName={"name"}
                    error={errors.lga}
                  />
                }
              />
            </div>
            <div className="col-lg-12 mb-7">
              <ListViewInCanvas
                title={"Email"}
                ItemName={userData?.email}
                edit={edit}
                wrapperClassName="mb-3"
                labelClassName={"primaryI"}
                defaultValue={values?.email}
                name="email"
                onChange={updateField}
                error={errors.email}
              />
            </div>
            <div className="row mb-[64px] gap-[15px] justify-center mt-[50px]">
              <div className="col-5">
                <ButtonComp
                  text={"Cancel"}
                  btnClassName={
                    "text-[14px] lg:text-[16px] w-full py-[5px] lg:py-[13px] px-[20px] lg:px-[34px] border-[0.64px] rounded-full border-[#D63434] text-[#D63434]"
                  }
                />
              </div>
              <div className="col-5">
                <ButtonComp
                  loader={isLoading}
                  type="submit"
                  onClick={handleSubmit}
                  btnClassName={
                    "text-[14px] lg:text-[16px] w-full py-[5px] lg:py-[13px] px-[25px] lg:px-[47px] border-[0.64px] rounded-full border-[#3DA84A] text-[#fff] bg-[#3DA84A]"
                  }
                  text={"Proceed"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
