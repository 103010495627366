/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { TableComp } from "../../Common/TableComp";
import { useNavigate, useParams } from "react-router-dom";
import OffCanvasComp from "../../Ui/OffCanvas";
import TopHeader from "../../Common/TopHeader";
import SubHeader from "../../Common/SubHeader";
import { CustomerColumns } from "./Data";
import ViewCustomerDetailsComp from "./submodules/ViewProductDetails";
import {
  useGetAllCustomerQuery,
  useGetAllLeadsQuery,
  useUpdateLeadsMutation,
} from "../../../store/Customer/CustomerApi";
import { FiEdit } from "react-icons/fi";
import ButtonComp from "../../Ui/Button";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useGetAllCompaniesQuery } from "../../../store/DashBoard/DashBoardApi";
export default function ViewCustomerDetails() {
  const navigate = useNavigate();
  const {id} =useParams()
  const [showCavans, setShowCavans] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [LeadData,setLeadData]=useState();
  const [edit, setEdit] = useState(false);
  const { t } = useTranslation();

  const {
    data: CustomerData,
    // isLoading: CustomerLoader,
    // isSuccess: CustomerSuccess,
  } = useGetAllCompaniesQuery();
  const {
    data: LeadsData,
    // isLoading: LeadsLoader,
    // isSuccess: LeadsSuccess,
    refetch:LeadsRefetch
  } = useGetAllLeadsQuery();

  const [updateLeads,{isLoading,isError,data,error,isSuccess:updateLeadIsSuccess}] =useUpdateLeadsMutation()

  console.log(CustomerData, "CustomerData");
  useEffect(() => {
    if(id){
      setShowCavans(true)
    }
  }, [id])

  useEffect(() => {
   if(updateLeadIsSuccess){
    LeadsRefetch();
    toast.success('Lead Updated Successfully')
    navigate(`/companies/`);
    setShowCavans(false)
   }
  }, [ updateLeadIsSuccess,LeadsRefetch])

  useEffect(() => {
    if(isError){
      Array.isArray(error?.data?.message)?error?.data?.message?.map((item)=>
        toast.error(item)
      ):  toast.error(error?.data?.message);
    }
   
    
  }, [error?.data?.message, isError])
  
  

  const handleUpdateLeads =async (data) => {
    // updateLeads
   await updateLeads(LeadData);
    // console.log(dataResult,'handleUpdateLeads123')
    // console.log(LeadData,'handleUpdateLeads')
  }

    // console.log(error,isError,'handleUpdateLeads123')
  
  const tableDropDown = [
    {
      name: (
        <div className="text-[#656468] text-[13px] flex gap-2 items-center">
          <FiEdit size={18} color="#4F008A" /> View Company
        </div>
      ),
      action: (row) => {
        console.log(row, "row");
        navigate(`/companies/${row?.id}`);
        // setShowCavans(true);
      },
    },
   
    {
      name: (
        <div className="text-[#D63434] text-[13px] flex gap-2 items-center">
          <img alt="" src="/icon/red-trash.svg" />
          Delete Company
        </div>
      ),
      action: (row) => {
        console.log(row, "row");
        // setShowCavans(true);
      },
    },
  ];
  return (
    <div>
      <TopHeader
        title={`Companies (${CustomerData?.data?.length || 0})`}
        btnText={"Add Company's Admin"}
        addBtnAction={()=>navigate('/companies/add')}
        navigate={navigate}
      />
      <SubHeader
      showFilterBtn
      statusAllBtn
        sortBtn
        inputPlaceholder={"Search Customer, Phone Number,Location"}
        onChange={(e)=>setSearchField(e.target.value)}      />
      <div>
        <TableComp
          Data={CustomerData?.data
            ?.filter(
              (item) =>
                item?.name.toLowerCase().match(searchField?.toLowerCase()) ||
                item?.state
                  .toLowerCase()
                  .match(searchField?.toLowerCase())||
                   item?.contactPerson
                  .toLowerCase()
                  .match(searchField?.toLowerCase())
            )
            .reverse()}
          columnsComp={CustomerColumns(
            tableDropDown,
            CustomerData?.data?.length > 0 && [...CustomerData?.data]?.reverse()
          )}
          pagination
        />
      </div>
      <OffCanvasComp
        title={"Company Details"}
        // ButtonComp={<ButtonComp
        // loader={isLoading}
        // // onClick={()=>edit?handleUpdateLeads():setEdit(true)}
        // btnClassName={'bg-primaryI text-white py-2 px-5 rounded-full'}
        // text={edit?'Update Customer':'Edit Customer'}
        // />}
        show={showCavans}
        bodyComponent={<ViewCustomerDetailsComp handleSubmit={setLeadData} edit={edit} data={CustomerData?.data?.find((item)=>Number(item?.id)===Number(id))} />}
        handleClose={() => {
          setShowCavans(false)
          navigate("/companies");
          setEdit(false)
        }}
      />
    </div>
  );
}
