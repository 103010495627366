import React from 'react'
import AdminLayout from '../../Layout/AdminLayout'
import ProductComp from '../../Components/modules/Product'

export default function Product() {
  return (
    <AdminLayout active={2}>
      <ProductComp/>
    </AdminLayout>
  )
}
