import moment from 'moment/moment';
import React, { forwardRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import {IoIosArrowDown} from 'react-icons/io'
const DatePickerInput = forwardRef(({ value, onChange }, ref) => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button style={{borderColor:'#4F008A',background:'transparent'}} className="example-custom-input rounded-pill h14 px-4 py-1 flex gap-2" onClick={onClick} ref={ref}>
      {moment(value).format('ll') }{" "}<IoIosArrowDown size={20}/>
    </button>
  ));

  const handleDateChange = (date) => {
    setSelectedDate(date);
    onChange(date);
  };

  return (
    <DatePickerStyled>
    <DatePicker
      selected={selectedDate}
      onChange={handleDateChange}
      customInput={<CustomInput ref={ref} />}
    />
    </DatePickerStyled>
  );
});

export default DatePickerInput;


const DatePickerStyled =styled.span`
.react-datepicker-wrapper{
    width:auto !important;
}
`