import React from 'react'
import AdminLayout from '../Layout/AdminLayout'
import DashBoardComp from '../Components/modules/DashBoardComp'

 function DashBoard() {
  return (
    <AdminLayout active={1}>
      <DashBoardComp/>
    </AdminLayout>
  )
}

export default (DashBoard);
