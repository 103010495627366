import React from 'react'
import { Spinner } from 'react-bootstrap'

export default function ButtonComp({text,btnClassName,onClick,loader,wrapper,...props}) {
  return (
    <div className={wrapper}>
        <button onClick={onClick} className={`flex gap-2 items-center justify-center ${btnClassName}`} {...props}>{loader&&<Spinner size='sm' animation="border" /> }{text}</button>
    </div>
  )
}




