import React from "react";
import InputComp from "../Ui/InputComp";
import { FiSearch } from "react-icons/fi";
import DropDownComp from "../Ui/DropDown";
import { ImageComponent } from "./FallbackImage";
import { CategoryData } from "../modules/Data";
import DateFilter from "./DateFilter";
import { useTranslation } from "react-i18next";

export default function SubHeader({
  sortBtn,
  CategoryBtn,
  statusAllBtn,
  filterBtn,
  inputPlaceholder,
  onChange,
  setSortField,
  filterTitle,
  filterLoader,
  filterStartDate,
  filterEndDate,
  filterStartSetDate,
  filterEndSetDate,
  showFilterBtn
}) {
  const sortItem = [
    {
      name: "Product Id",
      action: (row) => setSortField(1),
    },
    {
      name: "Name",
      action: (row) => setSortField(3),
    },
  ];
  const { t } = useTranslation();
  return (
    <div className="row mb-[43px]">
      <div className="col-lg-5 col-xl-4 mb-3 mb-lg-0">
        <InputComp
          suffixIcon={
            <div className="pr-2">
              {<FiSearch size={25} color="#4F008A" className="" />}
            </div>
          }
          placeholder={inputPlaceholder || t(`lang.SFP`)}
          onChange={onChange}
        />
      </div>
      <div className="col-lg-6 col-xl-8 flex  justify-start lg:justify-end gap-4">
        {false && (
          <DropDownComp
            options={sortItem}
            dropDownTextClassName={
              "bg-[#EDE6F3] px-3 flex rounded-md h-[41px] "
            }
            dropDownText={
              <span className="flex gap-[5px] items-center px-2 text-[14px]">
                Sort By
                <ImageComponent src="/icon/sort.svg" width={18} height={18} />
              </span>
            }
          />
        )}
       {!showFilterBtn && <DateFilter filterTitle={filterTitle} filterLoader={filterLoader}
         filterStartDate={filterStartDate} 
         filterEndDate={filterEndDate}
         filterStartSetDate={filterStartSetDate}
         filterEndSetDate={filterEndSetDate}
         filterBtn={filterBtn}
         />
          }
        {!statusAllBtn && (
          <DropDownComp
            options={CategoryData}
            dropDownTextClassName={
              "bg-[#EDE6F3]  px-2 flex rounded-md h-[41px]"
            }
            dropDownText={
              <span className="flex gap-[5px] items-center px-2 mb-0  text-[14px]">
                <span className="me-3">Status:</span> All
                <ImageComponent src="/icon/select.png" width={7} height={18} />
              </span>
            }
          />
        )}
        {false && (
          <DropDownComp
            options={CategoryData}
            dropDownTextClassName={
              "bg-[#EDE6F3]  px-2 flex rounded-md h-[41px]"
            }
            dropDownText={
              <span className="flex gap-[5px] items-center px-2 mb-0  text-[14px]">
                <span className="me-3">Category :</span> All
                <ImageComponent src="/icon/select.png" width={7} height={18} />
              </span>
            }
          />
        )}
      </div>
    </div>
  );
}
