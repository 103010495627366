import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../api/baseUrl";
import { baseQuery } from "../api";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQuery,

  endpoints: (builder) => ({
    getUserProfile: builder.query({
      query: (body) => ({
        url: '/auth/me',
        method: "GET",
        body,
      }),
    }),

   

   
  }),
});

export const {
  useGetUserProfileQuery,
 
} = userApi;
