import React, { useState } from "react";
import InputComp from "../../../Ui/InputComp";
import ButtonComp from "../../../Ui/Button";
import { ImageComponent } from "../../../Common/FallbackImage";
import { useAddDynamicPricingMutation } from "../../../../store/Product/ProductApi";
import { toast } from "react-toastify";

export default function AddDynamicPricing({setShowDynamicPricing}) {
  const [addDynamicPricing,{isLoading,isError,isSuccess}]=useAddDynamicPricingMutation();
  const [inputs, setInputs] = useState([
    { id: 1, category: "", price: "" },
    // { id: 2, name: "", price: "" },
  ]);
  const [nextId, setNextId] = useState(2);

  const [inputsI, setInputsI] = useState([
    { id: 1, category: "", price: "" },
    // { id: 2, name: "", price: "" },
  ]);
  const [nextIdI, setNextIdI] = useState(2);

  const handleInputChange = (id, field, e) => {
    let value = e.target.value;
    if (field === 'price') {
      // Validate the input to allow only numbers or an empty string
      value = value.replace(/[^0-9]/g, '');
    }

    const newInputs = inputs.map(input => {
      if (input.id === id) {
        return { ...input, [field]: value };
      }
      return input;
    });
    setInputs(newInputs);
  };

  const handleAddInputs = () => {
    const newInputs = [...inputs, { id: nextId, name: "", price: "" }];
    setInputs(newInputs);
    setNextId(nextId + 1);
  };

  const handleInputChangeI = (id, field, e) => {
    let value = e.target.value;
    if (field === 'price') {
      // Validate the input to allow only numbers or an empty string
      value = value.replace(/[^0-9]/g, '');
    }

    const newInputs = inputsI.map(input => {
      if (input.id === id) {
        return { ...input, [field]: value };
      }
      return input;
    });
    setInputsI(newInputs);
  };

  const handleAddInputsI = () => {
    const newInputs = [...inputsI, { id: nextIdI, name: "", price: "" }];
    setInputsI(newInputs);
    setNextIdI(nextIdI + 1);
  };

  const handleKeyPress = e => {
    const invalidChars = ['-', '+'];
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleSubmit = async() =>{
    const newArray =[...inputs,...inputsI];
    const response =await addDynamicPricing(newArray);
    if(response?.data){
      toast.success('Price created....')
      setShowDynamicPricing(false)
    }
    else{
      toast.error('oops, something went wrong ')
    }
    console.log(response,'newArray')
    // console.log(newArray,'newArray')

  }

  

  return (
    <div className="px-3">
      <div className="text-[18px] text-[#292D32] font-semibold mb-5">
        Add Dynamic Prices
      </div>
      <div className="bg-[#F6F2F9] rounded-sm py-4 px-3 mb-4">
        <div className="text-[#282728] font-medium text-[18px] mb-4">
          Based On Customer Type
        </div>

        <form className="row">
          {inputs?.map((input) => (
            <div className="row mb-3 animate__fadeIn animate__animated">
              <div className="col-lg-6 ">
                <InputComp
                  onChange={(e) => handleInputChange(input.id, "category", e)}
                  value={input.category}
                  name={`category${input?.id}`}
                  label={"Customer Type"}
                  labelClassName={"text-[#4F008A] !mb-1"}
                  placeholder={"Choose a Category"}
                  containerClassName={"bg-white"}
                  type='text'
                />
              </div>
              <div className="col-lg-6">
                <InputComp
                  value={input.price}
                  onChange={(e) => handleInputChange(input.id, "price", e)}
                  name={`price${input?.id}`}
                  label={"Price (NGN)"}
                  labelClassName={"text-[#4F008A] !mb-1"}
                  placeholder={"1,000,000"}
                  containerClassName={"bg-white"}
                  type='number'
                  onKeyPress={handleKeyPress}
                />
              </div>
            </div>
          ))}
          <div
            className="flex justify-center items-center text-[16px] text-[#3DA84A] mt-3 gap-2 font-medium cursor-pointer"
            onClick={handleAddInputs}
          >
            <ImageComponent src="/icon/plus.svg" />
            Add More
          </div>
        </form>
      </div>

      <div className="bg-[#F6F2F9] rounded-sm py-4 px-3 mb-4">
        <div className="text-[#282728] font-medium text-[18px] mb-4">
          Based On Location
        </div>
        <form className="row">
        {inputsI?.map((input) => (
            <div className="row mb-3 animate__fadeIn animate__animated">
          <div className="col-lg-6">
            <InputComp
              label={"Location (State)"}
              labelClassName={"text-[#4F008A] !mb-1"}
              placeholder={"Choose a State"}
              // inputClassName={'bg-white'}
              containerClassName={"bg-white"}
              onChange={(e) => handleInputChangeI(input.id, "category", e)}
              value={inputsI.category}
            />
          </div>
          <div className="col-lg-6">
            <InputComp
              label={"Price (NGN)"}
              labelClassName={"text-[#4F008A] !mb-1"}
              placeholder={"1,000,000"}
              containerClassName={"bg-white"}
              onChange={(e) => handleInputChangeI(input.id, "price", e)}
              value={inputsI.price}
              inputMode="numeric"
              type='number'
            onKeyPress={handleKeyPress}
            // error={'Hello'}
            />
          </div>
          </div>
              ))}
          <div className="flex justify-center items-center text-[16px] text-[#3DA84A] mt-3 gap-2 font-medium cursor-pointer" onClick={handleAddInputsI}>
            <ImageComponent src="/icon/plus.svg" />
            Add More
          </div>
        </form>
      </div>

      <div className="flex justify-center gap-[20px] py-5">
        <ButtonComp
          // onClick={}
          text={"Cancel"}
          btnClassName={
            "border-[#D63434] border-[1px] text-[#282728] rounded-full px-[35px] py-2 text-[18px] font-medium"
          }
        />
        <ButtonComp
            onClick={handleSubmit}
            loader={isLoading}
          text={"Save Prices"}
          btnClassName={
            "border-[#4F008A] bg-[#4F008A] border-[1px]  rounded-full px-[45px] text-white py-2 text-[18px] font-medium"
          }
        />
      </div>
    </div>
  );
}
