import { BsThreeDotsVertical } from "react-icons/bs";
import DropDownComp from "../../Ui/DropDown";
import moment from "moment";
import { statusStyleII } from "../Customer/Data";
import { formatMoney } from "../../../utils/formatMoney";

export const TopAreaColumns = (tableDropDown,Data) => {
  return [
    {
      name: "ID",
      sortable: true,
      selector: (row) =>         Data?.length > 0 && Data?.findIndex((item) => item?.address === row?.address) + 1,
      width:'70px'
    },
 
    {
      name: "Address",
      sortable: true,
      selector: (row) => `${row?.address}`,
    },

    {
      name: "Local Government",
      sortable: true,
      selector: (row) => row?.lga,
    },
    {
      name: "Field Staff(s)",
      sortable: true,
      // selector: (row) => moment(row?.scheduleDate).format("MMM Do YY"),
      selector: (row) => row?.fieldStaff,
    },
    {
      name: "Total Sales Order (NGN)",
      sortable: true,
      selector: (row) => formatMoney(row?.totalAmount),
    },
    // {
    //   name: "Location",
    //   sortable: true,
    //   selector: (row) => row.customer?.address,
    // },
    // {
    //   name: "Status",
    //   sortable: true,
    //   selector: (row) => <div className={`capitalize w-full text-center ${statusStyleII(row.status)}`}>{row.status}</div>,
    // },
    {
      name: "Action",
      // selector: (row) => <DropDownComp dropDownText={<BsThreeDotsVertical color="#7572EA" />}/>,
      cell: (row) => ( <DropDownComp row={row} options={tableDropDown} dropDownText={<BsThreeDotsVertical color="#7572EA" />}/>)
    },
  ];
};

export const CategoryData = [
  {
    name: (
      <span className="text-[14px] flex py-[4px] ">
        <div className="w-[23px] h-[23px] rounded-pill mr-[12px] bg-[#4F008A] text-[8px] flex justify-center items-center text-white">
          400
        </div>
        All
      </span>
    ),
  },
  {
    name: (
      <span className="text-[14px] flex text-[#656468] py-[4px] pr-[3rem] ">
        <div className="w-[23px] h-[23px] rounded-pill mr-[12px] bg-[#A67FC4] text-[8px] flex justify-center items-center text-white">
          400
        </div>
        Pharmacy Channel
      </span>
    ),
  },
  {
    name: (
      <span className="text-[14px] flex text-[#656468] py-[4px] ">
        <div className="w-[23px] h-[23px] rounded-pill mr-[12px] bg-[#A67FC4] text-[8px] flex justify-center items-center text-white">
          400
        </div>
        Wholesales Chan...
      </span>
    ),
  },
  {
    name: (
      <span className="text-[14px] flex text-[#656468] py-[4px] ">
        <div className="w-[23px] h-[23px] rounded-pill mr-[12px] bg-[#A67FC4] text-[8px] flex justify-center items-center text-white">
          400
        </div>
        GPO Channels
      </span>
    ),
  },
];
