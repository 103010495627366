import React from 'react'
import ButtonComp from '../../Ui/Button'

const Privacy_Policy = () => {

    const items = [
        {
          header: 'Data Centers',
          content: 'Data centers are a critical component of modern organizations and play a crucial role in supporting business.. ',
        },
        {
          header: 'Network Security',
          content: 'Network security is crucial because networks are the backbone of modern organizations and are used to transmit sensitive.. ',
        },
        {
            header: 'System Security',
            content: 'Network security is crucial because networks are the backbone of modern organizations and are used to transmit sensitive.. ',
          },

          {
            header: 'Security Operations',
            content: 'Data centers are a critical component of modern organizations and play a crucial role in supporting business... ',
          },

          {
            header: 'Access Control',
            content: 'Network security is crucial because networks are the backbone of modern organizations and are used to transmit sensitive.. ',
          },

          {
            header: 'Vulnerabilty Assesment and Penetration Testing',
            content: 'Data centers are a critical component of modern organizations and play a crucial role in supporting business... ',
          },

          {
            header: 'Application Level Security',
            content: 'Network security is crucial because networks are the backbone of modern organizations and are used to transmit sensitive.. ',
          },

          {
            header: 'Data Protection, Continuity, and Retention:',
            content: 'Network security is crucial because networks are the backbone of modern organizations and are used to transmit sensitive.. ',
          },

          {
            header: 'Application Level Security',
            content: 'Network security is crucial because networks are the backbone of modern organizations and are used to transmit sensitive.. ',
          },

          {
            header: 'Data Protection, Continuity, and Retention:',
            content: 'Network security is crucial because networks are the backbone of modern organizations and are used to transm',
          },
        
      ];
  return (
    <div>
        <div className='flex flex-col md:flex-row  justify-between items-center'> 

            <div className='text-center   md:text-start mt-8'>  
                <h1 className='text-lg md:text-start font-bold' >  Privacy Policies</h1>
                <p className='text-sm'> How We Store, Process, and Secure Your Data </p>
            </div>

            <div className='flex space-x-5'>
            <ButtonComp

            text={"Contact Us"}
            btnClassName={"  px-4 border-2  border-[#BDA0D3] py-2 rounded-pill"}
            />
                
            </div>
            </div>

        <div className='md:grid grid-cols-2'> 
                {items.map((item, index) => (
                <div key={index} className="mb-4 p-2    ">
                <h2 className="text-lg  text-[#4F008A] font-medium">{item.header}</h2>
                <p className="mt-2 px-2 py-4 border border-gray-300 rounded-md">{item.content} <span className='text-[#4F008A]'> Continue Reading </span> </p>
                </div>
            ))}
        </div>
    </div>
  )
}

export default Privacy_Policy
