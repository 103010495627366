import React from "react";

export default function InputComp({
  suffixIcon,
  suffixIconClassName,
  prefixIcon,
  prefixIconClassName,
  inputClassName,
  containerClassName,
  label,
  labelClassName,
  wrapperClassName,
  placeholder,
  labelRight,
  onChange,
  error,
  ...props
}) {
  return (
    <div className={wrapperClassName}>
      {label && (
        <div
          className={`mb-[22px] font-medium flex justify-between border-red-100 items-center  ${labelClassName}`}
        >
          {label}
          {labelRight && <div>{labelRight}</div>}
        </div>
      )}
      <div
        className={`border-[1px] border-[#EDE6F3] rounded-md px-2 py-1 h-[50px] flex gap-1 items-center focus:border-primary-600  ${containerClassName}`}
      >
        {suffixIcon && <div className={suffixIconClassName}>{suffixIcon}</div>}
        <input
          className="w-full outline-none !border-0 border-none"
          placeholder={placeholder}
          {...props}
          // inputMode="numeric"
          inputMode="numeric"
          step="any"
          onChange={onChange}
        />

        {prefixIcon && <div className={prefixIconClassName}>{prefixIcon}</div>}
      </div>
      {error && (
        <div className="text-danger text-start ps-1">
          {label} {error}
        </div>
      )}
    </div>
  );
}
