import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
            lang:{
                Filter : "Filter ",
                world: "World",
                Overview: "Overview",
                TSO:"Total Sales Orders (NGN)",
                TP:'Top Products ',
                Jan:'Jan',
                Feb:'Feb',
                Mar:'Feb',
                Apr:'Apr',
                May:'May',
                June:'June',
                July:'July',
                Aug:'Aug',
                Sept:'Sept',
                Oct:'Oct',
                Nov:'Nov',
                Dec :'Dec ',
                TA: 'Top Areas',
                Price:'Price',
                Availability :'Availability ',
                PS:'Product SKU',
                PN:'Product Name',
                SM:'See more',
                AP:'Add Product',
                PC:'Product Composition',
                NAFDAC:'NAFDAC Registration Number',
                PP:' Product Price',
                AS:'Availability Status',
                PD:' Product Description',
                SL:'Shelf Life (Years)',
                DASH:'Dashboard',
                PRO:'Product',
                MQ:'Manage Orders',
                LEAD:'Leads',
                CUSTOMER:'Customer',
                SCH:'Schedule',
                AC:'Admin Console',
                LG:'Log Out',
                TD:'Total Debt',
                TSC:'Total Sales Collections',
                TOC:'Total Order Count',
                TPO:'Total Paid Order',
                UCB:'User Chart Board',
                MA:'months ago',
                SO:'Sales Order',
                RO:'Recent Orders',
                VA:'View All',
                P:'Products',
                SFP:'Search For Products',
                R:'Report',
                OM:'Orders Management',
                OMS:'Search Orders, Field Staffs, Customer',
                S:'Search',
                C:'Customers',
                AC:'Add Customer',
                CAS:'Create New Schedule',
                AC:'Admin Console',
                PI:'Personal Information',
                PW:'Password',
                NFC:'Notification'
            }
          // here we will place our translations...
        }
      },
      fr: {
        translation: {
            lang:{
                Filter : "Filtre",
                world: "World",
                Overview: "Tableau de bord",
                TSO:'Total des commandes (CFA)',
                TP:' Meilleurs produits',
                Jan:'janvier',
                Feb:'février',
                Mar:'mars',
                Apr:'avril',
                May:'mai',
                June:'juin',
                July:'juillet',
                Aug:'août',
                Sept:'septembre',
                Oct:'octobre',
                Nov:'novembre',
                Dec :'décembre  ',
                TA:'Principaux domaines',
                Price:'Prix',
                Availability :'disponibilité',
                PS:'UGS du produit',
                PN:'Nom du produit',
                SM:'Voir plus',
                AP:'Ajouter un produit',
                PC:'Composition du produit',
                NAFDAC:'Numéro d’enregistrement NAFDAC',
                PP:'Prix du produit',
                AS:' État de disponibilité ',
                PD:'Description du produit',
                SL:'Durée de Conservation (Années)',
                DASH:'Tableau de bord',
                PRO:'Produit',
                MQ:'Gérer les commandes',
                LEAD:'Prospects',
                CUSTOMER:'Client',
                SCH:'Planning',
                AC:`Console d'administration`,
                LG:'se déconnecter',
                TD:'Dette Totale',
                TSC:'Encaissements totaux des ventes',
                TOC:'Nombre total de commandes',
                TPO:'Commande totale payée',
                UCB:'Tableau de bord des utilisateurs',
                MA:'il y a plusieurs mois',
                SO:'Commande de vente',
                RO:'Commandes récentes',
                VA:'Voir tout',
                P:'Produits',
                SFP:'Rechercher des produits',
                R:'Rapport',
                OM:'Gestion des commandes',
                OMS:'Rechercher les commandes, les membres du personnel sur le terrain, les clients.',
                S:'Rechercher',
                C:'Clients',
                AC:'ajouter des clients',
                CAS:'créer un emploi du temps',
                AC:"Console d'administration",
                PI:'Informations personnelles',
                Pw:'mot de passe',
                NFC:'Notification'




            }
          // here we will place our translations...
        }
      }
    }
  });

export default i18n;