import React from 'react'
import AdminLayout from '../../Layout/AdminLayout'
import ViewLeadDetails from '../../Components/modules/Lead'

export default function Leads() {
  return (
    <AdminLayout active={7}>
      <ViewLeadDetails/>
    </AdminLayout>
  )
}
