import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useFormValidator from "use-form-input-validator";
import InputComp from "../Components/Ui/InputComp";
import { useUserLoginMutation } from "../store/auth/authApi";
import ButtonComp from "../Components/Ui/Button";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setUserData } from "../store/auth";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(true);
  const [userLogin, { isLoading, isSuccess, data }] = useUserLoginMutation();
  const { values, errors, updateField, isAllFieldsValid } = useFormValidator({
    username: { checks: "required|email", value: "" },
    password: { checks: "required", value: "" },
  });

  useEffect(() => {
    if (isSuccess) {
      console.log(data?.data?.user, "find");
      if (data?.data?.user?.userRole === "admin") {
        // dispatch()
        toast.success("User Login Successfully...");

        dispatch(setUserData(data?.data));
        navigate("/");
      } else {
        toast.error("User not Authorized...");
      }
      // dispatch()
      // dispatch(setUserData(data?.data))
      // navigate('/')
    }
  }, [isSuccess, navigate, dispatch, data]);

  const handleSubmit = async (e) => {
    console.log(values, "values");
    e.preventDefault();
    // if (isAllFieldsValid() && productCategory && status) {
    if (isAllFieldsValid()) {
      const loginDetails = await userLogin(values);
      const { data, error } = loginDetails;
      if (error?.data?.message) {
        toast.error(error?.data?.message);
      }

      console.log(data?.data, error, "handleAddProduct");
    }
  };

  return (
    <div className="flex flex-wrap xl:flex-nowrap ">
      <div className="w-full xl:w-[50%]  scrollable">
        <div className=" xl:h-[100vh] overflow-y-scroll pb-[100px] ">
          <div className="py-[30px] pl-[20px] lg:pl-[72px] border-b-rgba(19, 8, 3, 0.1) border-b-[1px] mb-[60px] ">
            <img alt="" src="/icon/logo.svg" />
          </div>
          {/*  */}
          <div className="px-[30px] lg:pl-[72px]">
            <div className="mb-5">
              <h3 className="text-[32px] font-semibold mb-[0px]">Sign In</h3>
              <p className="text-[#333333]">
                Enter your credentials to use your account.{" "}
              </p>
            </div>
            <form
              className="w-full lg:pr-[119px] row gy-4"
              autoComplete="off"
              // onSubmit={handleSubmit}
            >
              <InputComp
                label={"Email"}
                name="username"
                onChange={updateField}
                value={values.username}
                error={errors.username}
                placeholder={"Enter your email address"}
              />
              <div className="mb-4">
                <InputComp
                  label={"Password"}
                  name="password"
                  onChange={updateField}
                  type={showPassword ? "password" : "text"}
                  value={values.password}
                  error={errors.password}
                  placeholder={"Enter your password"}
                  prefixIcon={
                    <div
                      className="mx-3"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <AiOutlineEyeInvisible size={25} />
                      ) : (
                        <AiOutlineEye size={25} />
                      )}
                    </div>
                  }
                />
                <div className="text-[#4F008A] text-end text-[14px] mt-[4px]">
                  Forgot Password?
                </div>
              </div>

              {/* <TextInputComp label={""} /> */}
              <ButtonComp
                onClick={handleSubmit}
                loader={isLoading}
                text={"Sign In"}
                btnClassName={
                  "bg-[#44AA43] text-white py-[10px] rounded-full w-full text-center font-medium "
                }
              />
              {/* <div className='text-[#282728]'>Don’t have an account yet? <span className='text-[#4F008A] hover:underline cursor-pointer'>Request for Demo or Start Free Trial</span> </div> */}
            </form>
          </div>
          {/*  */}
        </div>
      </div>
      <div className="w-full    xl:w-[50%]  relative d-none d-xl-block">
        <div className=" bg-[#4F008A]  h-[100vh] w-full bg-[url('/public/icon/background1.png')] bg-contain text-center   top-0 bottom-0">
          <div className="relative text-center">
            <div className="pt-[30px] flex justify-center align-bottom">
              <img
                src="/icon/back2.png"
                alt=""
                className="  w-[60%] object-contain"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
