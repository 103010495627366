import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./auth";
import { authApi } from "./auth/authApi";
import { getAuthToken, getToken } from "../utils/helper";
import { userApi } from "./User/userApi";

import { DashBoardApi } from "./DashBoard/DashBoardApi";

import { ProductApi } from "./Product/ProductApi";
import { OrderApi } from "./Order/OrderApi";
import { CustomerApi } from "./Customer/CustomerApi";

const result = getToken();

export const store = configureStore({
  // preloadedState: result ? { auth: result } : undefined,
  reducer: {
    auth: authSlice.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
   
    [DashBoardApi.reducerPath]: DashBoardApi.reducer,
    [ProductApi.reducerPath]: ProductApi.reducer,
    [OrderApi.reducerPath]: OrderApi.reducer,
    [CustomerApi.reducerPath]: CustomerApi.reducer,
 
    // [managerApi.reducerPath]: managerApi.reducer,
    // [adminApi.reducerPath]: adminApi.reducer,
    // managers: mangerReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      userApi.middleware,
 
      DashBoardApi.middleware,
      ProductApi.middleware,
      OrderApi.middleware,
      CustomerApi.middleware,
    
    ),
});

export default store
// setupListeners(store.dispatch);
